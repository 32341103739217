<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../api/plannedCapacityUtilizationService'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    window.deleteAll = () => service.deleteAll()
    service.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
